import React, { useContext, useEffect } from "react";
import NavBar from "../components/NavBat/NavBar";
import Header from "../components/header/header";
import RefContainer from "../components/RefContainer/RefContainer";
import { Context } from "..";
import { AliveScope } from "react-activation";
function Referals() {
  
const {user} = useContext(Context)
  return (
 <>
    <Header lang={user.language}></Header>
    <RefContainer></RefContainer>
    <AliveScope>
      <NavBar activeNum={3}></NavBar>
    </AliveScope>
 </>
  );
}

export default Referals;
