
import React, { useContext, useEffect, useState, useRef } from "react";
import cls from './Nut.module.css';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { updateUser } from "../../http/userAPI";

const NutMain = observer(() => {
  const { user } = useContext(Context);
  const cicleTime = 120;

  const parseTime = (timeStart) => {
    const time = cicleTime  - timeStart;
    const hourse = Math.floor(time / 3600);
    const minuts = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);


    const answer = hourse >= 0 ? `${hourse}:${minuts}:${seconds}` : `${12}:${0}:${0}`
    return answer;
  } 

  const [time, setTime] = useState(0);
  const [timeParse, setTimeParse] = useState(parseTime(0));
  const [nutSize, setNutSize] = useState(1);
  const [nutOpac, setNutOpac] = useState(1);
  const [numCount, setNumCount] = useState(0);
  const [numsArr, setNumArr] = useState([]);
  const [visible, setVisible] = useState('none');
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [audio] = useState(new Audio('/sounds/destroy.mp3'));
  const imgRef = useRef(null);
  const [isLoad, seIsLoad] = useState(false);
  const [isExplain, setIsExplain] = useState(false);

  function playDestroy() {
    audio.play()
  }

  useEffect(() => {
    if (user.time !== undefined) {
      const postTime = user.time < 4 * cicleTime ? user.time : cicleTime * 4; // Замените на ваше условие
      setTime(postTime);
      seIsLoad(true);
      console.log(user.time, time);
    }
  }, [user.time]);

  useEffect(() => {

    setTimeout(() => {
      setTimeParse(parseTime(time));
      setNutSize(getSize(time));
        setNutOpac(getSize(time));
      setNumCount(Math.floor(time / cicleTime)); 
      console.log('time', time)
    }, 10)
    
  }, [isLoad]);

  const getStyleCircle = () => {
    const circumference = 2 * Math.PI * 68;
    const offset = circumference - (getProccent(time) / 100 * 0.66) * circumference;
    return {
      strokeDasharray: `${circumference} ${circumference}`,
      strokeDashoffset: offset
    };
  };

  const getProccent = (time) => (time >= cicleTime ? 100 : Math.floor(time / (cicleTime / 100)));

  const getSize = (time) => (time < cicleTime / 2 ? 0.4 : getProccent(time) / 100);

  const [className, setClassName] = useState(cls.nutCont);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (!user.hasAnim) {
      console.log(!user.hasAnim)
        setClassName(`${cls.nutCont} ${cls.nutStart}`);
        setHasAnimated(true);
        user.setHasAnim(true);
        
    } else {
      setTimeout(() => {
        setClassName(`${cls.nutCont}`)
      }, 2000)
     
    }
}, [hasAnimated]);


  const setNutSettings = () => {
    if (time <= cicleTime * 4 && time > 0) {
      console.log(time, cicleTime, time % cicleTime);
      setNumCount(Math.floor(time / cicleTime)); 
    }
  };


  useEffect(() => {
  
    const interval = setInterval(() => {
      if(time <= cicleTime * 4) {
        setNutSettings(); 
        setTime(prevTime => prevTime < cicleTime * 4 ? prevTime + 1 : prevTime); 
        setNutSize(getSize(time));
        setNutOpac(getSize(time));
      }
    }, 1000); 
    return () => clearInterval(interval);
  }, [isLoad, time]);

  useEffect(() => {

    // Обновляем timeParse, когда time меняется
    setTimeParse(parseTime(time)); 
    if(time >= 0 && time < 4 * cicleTime) {
      if(time % cicleTime === 0) {
        
      }
    }
  }, [time]);


  const diskNut = async (event) => {
    console.log(numCount)
    if (numCount <= 0 || isExplain) return;

    setTime(prevTime => prevTime - cicleTime);
    setX(event.clientX);
    setY(event.clientY - 145);
    

    setTimeout(() => {
      setNumCount(prevCount => prevCount - 1);
    }, 500);
    
    playDestroy();

    let num;
    const randomNum = Math.random();
    if (randomNum < 0.55) {
        num = 1000; // 55%
    } else if (randomNum < 0.75) {
        num = 500; // 20%
    } else if (randomNum < 0.95) {
        num = 2500; // 20%
    } else {
        num = 10000; // 5%
    }
    user.setCoins(user.coins + num);
    await updateUser(true, num, user.id);
    setNumArr(prevArr => [...prevArr, num]);
    imgRef.current.src = '';
    imgRef.current.src = './images/img/explain.webp';
    setVisible('block');

    setIsExplain(true);

    setTimeout(() => {
      setNumArr(prevArr => prevArr.slice(0, -1));
      setVisible('none');
    }, 1000);
    setTimeout(() => {
      setIsExplain(false);
    }, 1300)

  };


  return (
    <div className={cls.main}>
      {numsArr.map(el => (
        <div className={cls.numsTap} key={el}>
          <span style={{ top: `${y - 200}px`, left: `${x}px` }} className={cls.numsFalling}>+{el}</span>
        </div>
      ))}
      <div className={className}>
      <div className={cls.back} style={{opacity: isExplain ? 0 : 1}}></div>
      {numCount === 0 &&
        <>
        <svg className={cls.loadCircle} style={{ width: '150', height: '150', opacity: isExplain ? 0 : 1}}>
          <circle style={getStyleCircle()} className={cls.procesing_ring} cx="75" cy="75" r="55" />
          
        </svg>
        </>
      }
        
        <div className={cls.time}>
          <div className={cls.nutContent} onClick={diskNut}>
            <img alt="nut" ref={imgRef} className={cls.explain} style={{ display: visible }} src="./images/img/explain.webp" />
            <div className={cls.nutBody} style={{opacity: isExplain ? 0 : 1}}>
              <img alt="nut" className={cls.Nut} style={{ transform: `scale(${nutSize})` }} src="./images/img/nut.svg" />
            </div>
            {numCount < 1 && <div className={cls.filterGreen}></div>}
            {numCount ? <div className={cls.num} style={{opacity: isExplain ? 0 : 1}}>{numCount}</div> : null}
            {!numCount ? <span className={cls.timeSpan} style={{opacity: isExplain ? 0 : 1}}>{timeParse}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
});

export default NutMain;