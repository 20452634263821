import React, { useEffect } from "react";
import DekstopCont from "../components/Dekstop/Dekstop";
function Dekstop() {
  

  return (
 <>
    <DekstopCont></DekstopCont>
 </>
  );
}

export default Dekstop;
