import { Component } from "react";
import Dekstop from './pages/dekstop';
import Login from "./pages/login";
import Home from "./pages/home";
import Tasks from "./pages/tasks";
import Referals from "./pages/referals";
import Faq from "./pages/FAQ";

export const publicRoute = [
    {
        path: '/login',
        Component: Login
    },
    {
        path: '/dekstop',
        Component: Dekstop
    },
    
]

export const authRoute = [
    {
        path: '/home',
        Component: Home
    },
    {
        path: '/tasks',
        Component: Tasks
    },
    {
        path: '/referals',
        Component: Referals
    },
    {
        path: '/FAQ',
        Component: Faq
    },
    {
        path: '/login',
        Component: Login
    },
]