import React, { useContext, useEffect, useState, useCallback } from "react";
import cls from './RefContainer.module.css';
import { Context } from '../../index.js';
import { useTranslation } from 'react-i18next';
import RefModal from "../RefModal/RefModal.jsx";
import { refs } from "../../http/userAPI.js";
import { observer } from "mobx-react-lite";

const RefContainer = observer(() => {
    const { t } = useTranslation();
    const { user } = useContext(Context);

    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [allCoins, setAllCoins] = useState(0);

    const fetchRefs = useCallback(async () => {
        try {
            const res = await refs(user.id);
            setData(res);
        } catch (e) {
            console.log(e);
        }
    }, [user.id]);

    useEffect(() => {
        window.history.pushState({ some: 'state' }, '', '/home');
        fetchRefs();
    }, [fetchRefs]);

    useEffect(() => {
        const totalCoins = data.reduce((acc, el) => acc + el.coins, 0);
        console.log(data);
        setAllCoins(totalCoins);
    }, [data]);

    const referals = data.map(el => ({
        image: el.referred.photoUrl,
        name: el.referred.username,
        coins: el.referred.coins,
        friends: el.referred.friends
    }));

    return (
        <>
            {data.length <= 0 ? (
                <div className={cls.main}>
                    <img src="./images/img/people.png" className={cls.banner} alt="" />
                    <h1 className={cls.refHeader}>{t('RefSecHeader')}</h1>
                    <div className={cls.list}>
                        <span className={cls.listHeader}>{t('RefSubHeader')}</span>
                        <ul className={cls.listCont}>
                            <li className={cls.listItem}>
                                <span>{t('RefListFir')}</span>
                                <p>{t('RefTextFir')}</p>
                            </li>
                            <li className={cls.listItem}>
                                <span>{t('RefListSec')}</span>
                                <p>{t('RefTextSec')}</p>
                            </li>
                            <li className={cls.listItem}>
                                <span>{t('RefListThir')}</span>
                                <p>{t('RefTextThir')}</p>
                            </li>
                        </ul>
                    </div>
                    <div className={cls.buttonCont} onClick={() => setModalVisible(true)}>
                        <button className={cls.buttonRef}>{t('RefButton')}</button>
                    </div>
                  
                </div>
            ) : (
                <>
                <div className={cls.mainSec}>
                    <img src="./images/img/people.png" className={cls.banner} alt="" />
                    <h1 className={cls.refHeader}>Invite Frens</h1>
                    <div className={cls.fredNuts}>
                        <img src="images/icons/nut.svg" alt="nut" />
                        <span className={cls.friendFarm}>{allCoins}</span>
                    </div>
                    <p className={cls.description}>{t('scores')}</p>
                    <span className={cls.text}>{data.length} frens</span>
                    <div className={cls.listSec}>
                        {referals.map((el, index) => (
                            <div className={cls.item} key={index}>
                                <div className={cls.leftCont}>
                                    <img className={cls.image} src={el.image == "" ? './images/p.png' : el.image} alt="friend" />
                                    <div className={cls.textCont}>
                                        <span className={cls.name}>{el.name}</span>
                                        <p className={cls.refs}>{el.friends || 0}</p>
                                    </div>
                                </div>
                                <span  className={cls.coins}>{Math.floor(data[index].coins || 0)}</span>
                            </div>
                        ))}
                    </div>
                </div>
                 {referals.length < 15 && (
                    <div className={cls.buttonCont} onClick={() => setModalVisible(true)}>
                        <button className={cls.buttonRef}>{t('RefButton')}</button>
                    </div>
                )}
                </>
            )}
              <RefModal visible={modalVisible} setVisible={setModalVisible}>
                </RefModal>
              
        </>
    );
});

export default RefContainer;
