import React, { useContext, useEffect } from "react";
import NavBar from "../components/NavBat/NavBar";
import Header from "../components/header/header";
import HomeContent from "../components/HomeContent/HomeContent";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { AliveScope } from "react-activation";

const Home = observer(() => {
  
const {user} = useContext(Context)
  return (
 <>
    <Header lang={user.language} userPhoto={'./images/qw.png'}></Header>
    <HomeContent></HomeContent>
    <AliveScope>
      <NavBar activeNum={1}></NavBar>
    </AliveScope>
    
 </>
  );
})

export default Home;
