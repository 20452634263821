import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserStore from './store/UserStore';
import './i18n';

export const Context = createContext(null);
console.log(process.env.REACT_APP_API_URL, 34)
const timestamp = new Date().getTime();
const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = `styles.css?t=${timestamp}`;
document.head.appendChild(link);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={
    {user: new UserStore()}
  }>
    <>
      <App />
    </>
  </Context.Provider>
  
);

