import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function PreventBackNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = () => {

      if (location.pathname !== '/home') {
        navigate('/home', { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location]);

  return null; 
}

export default PreventBackNavigation;
