import { useContext } from "react";
import {$host, $authHost} from "./index.js"
import { jwtDecode } from "jwt-decode";
import { Context } from "../index.js";

console.log(44, process.env.REACT_APP_API_URL);
//const path = "http://localhost:49546";
const path = 'https://dev.telegram-ka.ru'

export const login = async (id, tgData) => {
  try {
    const {data} = await $host.post(`${path}/api/user/login`, {
      id: id,
      telegramInitData: tgData
      
    }, {
      headers: {
        'Content-Type': 'application/json'
    }

    });

    localStorage.setItem('token', data.token)

  return jwtDecode(data.token);
  }
  catch(e) {
    console.log(e);
  }
    
}

export const updateUser = async (isTimeReset, coins, id) => {
  try {
    const response = await $authHost.post(`${path}/api/user/update`, {
      isTimeReset: isTimeReset, 
      coins: coins,
      id: id,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
    return response;
  }
  catch(e) {
    console.log(e);
  }
 
}

export const languageChange = async(id, language) => {
  try {
    const response = await $authHost.post(`${path}/api/user/languageChange`, {
      language: language, 
      id: id,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
    return response;
  }
  catch(e) {
    console.log(e);
  }
}


export const users = async (id, task_id, platform) => {
  const {data} = await $authHost.get(`${path}/api/user/users`);
  return data;
}

export const refs = async (id) => {
  const {data} = await $host.get(`${path}/api/referral/all?id=${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
    }
    }
  );
  return data;
}

export const updateAction = async (id) => {
  const data = await $authHost.post(`${path}/api/user/updateaction`, {
    id: id
  }, {
    headers: {
      'Content-Type': 'application/json',
    
    }
  }
);
  return data;
}

export const setName = async (name, id) => {
  const data = await $authHost.post(`${path}/api/user/setName`, {
    name: name,
    id: id
  }, {
    headers: {
      'Content-Type': 'application/json',
    
    }
  }
);
  return data;
}

export const checkSub = async(id, taskId, coins) => {
  const data = await $authHost.post(`${path}/api/user/check`, {
    id: id,
    taskId: taskId,
    coins: coins
  }, {
    headers: {
      'Content-Type': 'application/json'
  }
  })
  return data;
}

export const getTasks = async(id) => {
  const data = await $authHost.get(`${path}/api/userTask/all?userId=${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return data
}

export const coinsPost = async(id, coins, taskId) => {
  const data = await $authHost.post(`${path}/api/user/coins`,{
    id:id,
    coins:coins,
    taskId: taskId
  }, {
    headers: {
      'Content-Type': 'application/json'
  }
  })
  return data;
}