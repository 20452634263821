import React, { useEffect, useState } from "react";
import cls from './TasksContent.module.css';

import { Link } from "react-router-dom";
import Task from "../Task/Task";
import TaskList from "../TasksList/TasksList";

function TaskContent() {

  useEffect(() => {
    window.history.pushState({ some: 'state' }, '', '/home');
  }, [])
  const [taskCount, setTaskCount] = useState(0);
    return (
        <div className={cls.main}>
          <h1 className={cls.taskHeader}>{taskCount} tasks available</h1>
          <span className={cls.subheader}>We’ll reward you immediately with points after each task completion.</span>
          <TaskList setTaskCount={setTaskCount}></TaskList>
        </div>
    );
}

export default TaskContent;
