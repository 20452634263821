import React, { useContext, useEffect, useMemo, useState } from "react";
import { Routes, Route, Navigate, useNavigate, history } from "react-router-dom";
import { authRoute } from "../routes.js";
import { Context } from "../index.js";
import { observer } from "mobx-react-lite";
import Login from "../pages/login.jsx";
import { KeepAlive } from 'react-activation';
import Home from "../pages/home.jsx";
import { login } from "../http/userAPI.js";
import i18n from "../i18n.js";
import { Loader } from "phaser";
import Loading from "./LoadingScren/Loading.jsx";
import { isMobile } from 'react-device-detect';
import useHandleBackButton from './NavigationStack.jsx';
import Dekstop from "../pages/dekstop.jsx";
import PreventBackNavigation from "./preventHistory/preventHistory.jsx";

const tg = window?.Telegram?.WebApp;

const AppRouter = observer(() => {
    useHandleBackButton();
    const { user } = useContext(Context);
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(true);
    async function start() {
        try {
        
            if(!isMobile) {
                navigate('/dekstop');
                setTimeout(() => {
                    setIsLoad(false);
                }, 2500)
                
                return;
            }
            //const data = await login(1042650482, 'query_id=AAEW9nIrAwAAABb2ciuFY1WG&user=%7B%22id%22%3A7171405334%2C%22first_name%22%3A%22MaksBot%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22botmaksimka2003%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1725661102&hash=239ee6706933e74713aeda1aa880d7d26f172df570fcbf5fabe0bdf0800a9faa');
            const data = await login(Number(tg?.initDataUnsafe?.user?.id), tg?.initData);
            if(!data) {
                setTimeout(() => {
                    setIsLoad(false);
                }, 2500)
                navigate('/login')
            }
            user.setId(data.id); 
            if(data.lastAuth !== null) {
                user.setIsAuth(true);
                navigate('/home')
            }
            else {
                user.setIsAuth(false);
                navigate('/login')
            }
            
            user.setCoins(data.coins);
    
            
            user.setTime(data.start_farming);
            //user.setTime(255000)
            user.setPhoto(data.photo_url);
            user.setAction(data.actionTime);
            user.setLaguage(data.language);
            
            user.setUsername(data.username);
            console.log(data);
         
            setTimeout(() => {
                setIsLoad(false);
            }, 2500)
     
            i18n.changeLanguage(data.language.toUpperCase());
        } catch (e) {
            console.log(e);
        }
    }

    window.addEventListener("popstate", (event) => {  
        if(isMobile) {
            navigate('/home')
        }
    });

    useEffect(() => {
        start();
        const cacheFiles = async () => {
          const cache = await caches.open('my-cache');
          const urlsToCache = [
            'sounds/phone.mp3', // Замените на ваш путь
            'images/img/explain.webp'  // Замените на ваш путь
          ];
    
          await cache.addAll(urlsToCache);
        };
    
        cacheFiles().catch(console.error);
      }, []);

      useEffect(() => {
        
            setIsLoad(true);
            setTimeout(() => {setIsLoad(false)}, 2000)
        
      }, [user.isAuth])



    return useMemo(() => (
        <>
            <PreventBackNavigation></PreventBackNavigation>
            <Routes>
                {user.isAuth && authRoute.map(({ path, Component }) => (
                    <Route key={path} path={path} element={
                        <KeepAlive key={path}>
                            <Component />
                        </KeepAlive>
                    } />
                ))}
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/dekstop" element={<Dekstop />} />
              
            </Routes>
            
            
            <Loading visible={isLoad}></Loading>
            
        </>
    ), [isLoad]);
});

export default AppRouter;
