import React, { useEffect } from "react";
import LoginCont from "../components/LoginCont/LoginCont";

function Login() {
  

  return (
 <>
    <LoginCont></LoginCont>
 </>
  );
}

export default Login;
