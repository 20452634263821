import React, { useState } from "react";
import cls from './FaqList.module.css';
import FaqItem from "../FaqItem/FaqItem";

function FaqList() {
    const [activeIndex, setActiveIndex] = useState(null);

    const list = [
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
        {
            header:'Lorem Ipsum is simply?',
            body:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
        },
    ];

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className={cls.list}>
            {list.map((el, index) => {
                return (
                    <FaqItem 
                        key={index}
                        num={index} 
                        header={el.header} 
                        body={el.body} 
                        size={list.length}
                        index={index}
                        isActive={index === activeIndex}
                        onToggle={handleToggle}
                    />
                );
            })}
        </div>
    );
}

export default FaqList;
