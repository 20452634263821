import React, { useContext, useEffect, useState } from "react";
import cls from './LoginCont.module.css'
import { Context } from "../..";
import { setName } from "../../http/userAPI";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const tg = window?.Telegram?.WebApp;
let cicleTime = 120;

const LoginCont = observer(() => {
  const {user} = useContext(Context);
    const [inputValue, setInputValue] = useState(tg?.initDataUnsafe?.user?.username);
    const [idVal, setIsVal] = useState(0)
    const [rootCls, setRootCls] = useState(false)

    useEffect(() => {
      setInputValue(tg?.initDataUnsafe?.user?.username);
      setIsVal(user.id);
      console.log(idVal)
    }, [user.id])

    const navigate = useNavigate();
    const handleChange = async (event) => {
       
      setInputValue(event.target.value);
    };

    async function putName(event) {
      
      const pattern = /^(?!.*[_.]{2})[a-zA-Z0-9_]{2,32}$/;

 
  if (!pattern.test(inputValue)) {
    setRootCls(true);
  }
  else {
    try {
      const data = await setName(inputValue, user.id);
      console.log(data.data.message)
      if(data.data.message === 'valid') {
        user.setIsAuth(true);
        user.setUsername(inputValue);
        user.setTime(cicleTime);
        navigate('/home');
        
      }
    }
    catch(e) {
      setRootCls(true);
    }
    
  }




      
    }
    useEffect(() => {
      if (user.isAuth) {
          navigate('/home');
      }
  }, [user.isAuth, navigate]);

    
  return (
    <>
    
        <div className={cls.main}>
       
       </div>
       <div className={cls.login}>
       {
      idVal === 0 ?
      <>
      <h1 className={cls.errorMsg}>You don't have an invite link. You can visit our social networks and search there</h1>
      <div className={cls.social}>
        <Link to={'http://youtube.com'}><img src="./images/icons/tg.svg" alt="tg"></img></Link> 
        <Link to={'http://youtube.com'}><img src="./images/icons/x.svg" alt="x"></img></Link>
        <Link to={'http://youtube.com'}><img src="./images/icons/dis.svg" alt="discord"></img></Link>
      </div>
      </> :
      <>
      <h1>Login</h1>
      <div className={cls.inputCont}>
      <input 
        className={cls.input}
        value={inputValue} 
        onChange={handleChange}  
        placeholder="User name" 
        ></input>
        {
        rootCls ?
        <>
        <span className={cls.errMsg}>This username is already taken</span>
        <span className={cls.errImg}>!</span>
        </>
        :
        null
        }
      </div>
            
            <button className={cls.button} onClick={putName}>Continue</button>
            </>
    }
       </div>
    </>
    
  );
});

export default LoginCont;
