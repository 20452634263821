
import React, { useEffect, useRef, useContext, useState } from "react";
  import cls from './Loading.module.css';

const Loading = ({visible})  => {

    const rootCls = [cls.background];

    if(visible) {
        rootCls.push(cls.active);
    }
    return (
      <div className={rootCls.join(' ')}>
          <div className={cls.backphone}></div>
            <div className={cls.roatingNut}>
                <img src="./images/img/nut.svg" alt="nut"></img>
            </div>
            <span>Loading...</span>
      </div>
    );
  };
  
  export default Loading;

