import React, { useContext, useEffect } from "react";
import NavBar from "../components/NavBat/NavBar";
import Header from "../components/header/header";
import FaqContent from "../components/FaqContent/FaqContent";
import { Context } from "..";
import { AliveScope } from "react-activation";
function Faq() {
  
const {user} = useContext(Context)
  return (
 <>
    <Header lang={user.language} userPhoto={'./images/qw.png'}></Header>

    <FaqContent></FaqContent>
    <AliveScope>
      <NavBar activeNum={4}></NavBar>
    </AliveScope>
 </>
  );
}

export default Faq;
