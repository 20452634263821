import React, { useContext, useEffect } from "react";
import NavBar from "../components/NavBat/NavBar";
import Header from "../components/header/header";
import TaskList from "../components/TasksList/TasksList";
import TaskContent from "../components/TasksContent/TasksContent";
import { Context } from "..";
import { AliveScope } from "react-activation";
function Tasks() {

  const {user} = useContext(Context)

  return (
 <>
    <Header lang={user.language}></Header>
    <TaskContent></TaskContent>
    <AliveScope>
      <NavBar activeNum={2}></NavBar>
    </AliveScope>
 </>
  );
}

export default Tasks;
