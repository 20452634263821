import React, { useRef, useEffect, useContext, useState } from 'react';
import Phaser from 'phaser';
import { Context } from '../..';
import { updateUser } from '../../http/userAPI';

const PhaserCanvas = () => {
  const canvasRef = useRef(null);
  const { user } = useContext(Context);
  const [delay, setDelay] = useState(2000);
  const [prise, setPrise] = useState(1000);
  const [isVisible, setIsVisible] = useState(!document.hidden);
  let coinsPost = 0;

  useEffect(() => {
    // Сброс параметров после завершения действия пользователя
    let resetTimeout;

    if (user.isAction) {
      setPrise(10);
      setDelay(1000);
      console.log('start');

      resetTimeout = setTimeout(() => {
        setPrise(1);
        setDelay(2000);
        user.setIsAction(false);
        console.log('Reset');
      }, 10000); // Сброс через 10 секунд
    }

    // Очистка таймера при размонтировании или изменении зависимости
    return () => {
      if (resetTimeout) {
        clearTimeout(resetTimeout);
      }
    };
  }, [user.isAction]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;

      const config = {
        type: Phaser.CANVAS,
        parent: canvas.parentElement,
        canvas: canvas,
        backgroundColor: '#028af8',
        width: canvas.clientWidth,
        height: canvas.clientHeight,
        transparent: true,
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 300 },
            debug: false,
          },
        },
        scene: {
          preload: preload,
          create: create,
          update: update,
        },
      };

      const game = new Phaser.Game(config);
      canvasRef.current.phaserGame = game;

      // Добавляем слушатель для visibilitychange
      const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Очистка при размонтировании компонента
      return () => {
        game.destroy(true);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [user]);

  const preload = function () {
    this.load.image('particle', './images/img/fall.svg');
    this.load.image('obstacle', './images/img/fall.svg');
    this.load.audio('back', './sounds/phone.mp3');
    this.load.audio('take', './sounds/take.mp3');
    this.load.audio('fall', './sounds/fall.mp3');
  };

  const create = function () {
    this.soundBack = this.sound.add('back', { volume: 0.5, loop: true });
    this.soundTake = this.sound.add('take', { volume: 1 });
    this.soundFall = this.sound.add('fall', { volume: 1 });

    this.soundBack.play();

    this.obstacles = this.physics.add.group({
      key: 'particle',
      repeat: 0,
      setXY: { x: -300, y: 0 },
    });

    const createParticle = () => {
      if (!isVisible || !canvasRef.current) {
        return;
      }

      const particle = this.obstacles.create(
        Phaser.Math.Between(50, canvasRef.current.clientWidth - 50),
        -50,
        'obstacle'
      );
      particle.setScale(0.75);
      particle.isClick = false;
      particle.countFalls = 0;

      particle.body.setCircle(particle.width / 2);
      particle.setBounce(1);
      particle.setCollideWorldBounds(true);
      particle.setDrag(2.95);
      particle.setVelocity(
        Phaser.Math.Between(-200, 200),
        Phaser.Math.Between(-200, 200)
      );
      particle.setAngularVelocity(Phaser.Math.Between(-100, 100));
      particle.setBounce(0.8);
      particle.setGravityY(700);
      particle.setMaxVelocity(200, 500);

      particle.isOnGround = false;

      this.time.addEvent({
        delay: 6000,
        callback: () => {
          if (particle.active) {
            particle.destroy();
          }
        },
        callbackScope: this,
      });

      this.tweens.add({
        targets: particle,
        alpha: 0,
        duration: 1000,
        delay: 5000,
      });

      particle.setInteractive();
      particle.on('pointerdown', async () => {
        if (!particle.isClick) {
          const reward = user.isAction ? 10 : 1;
          user.setCoins(user.coins + reward);
          coinsPost += reward;

          if(coinsPost >= 5) {
            
            await updateUser(false, coinsPost, user.id);
            coinsPost = 0;
          }
          this.soundTake.play();

          const text = this.add.text(particle.x, particle.y, `+${reward}`, {
                
            fill: '#fff',
            fontFamily:'GilroyMedium',
            fontSize: '15px',
          });
          text.setOrigin(0.5, 0.5);

          this.tweens.add({
            targets: text,
            y: text.y - 50,
            alpha: 0,
            duration: 5000,
            onComplete: () => {
              text.destroy();
            },
          });
        }
        particle.isClick = true;
        this.tweens.add({
          targets: particle,
          alpha: 0,
          duration: 50,
          onComplete: () => {
            particle.destroy();
          },
        });
      });

      this.physics.add.collider(this.obstacles, this.obstacles);
    };
    const createTimer = async () => {
      while (true) {
  
        await new Promise((resolve) => setTimeout(resolve, getDelay()));
        if(!document.hidden && !user.isAction) {
          createParticle();
        }
      }
      
    };
    const createTimerSec = async () => {
      while (true) {
        
        await new Promise((resolve) => setTimeout(resolve, Phaser.Math.Between(1000, 2000)));
        if(!document.hidden && user.isAction) {
          createParticle();
        }
      }
      
    };
    
    createTimer();
    createTimerSec();

  };

  const update = function () {
    this.obstacles.children.each((particle) => {
      try {
        if (particle.body.blocked.down && !particle.isOnGround) {
          particle.setAngularVelocity(0);
          particle.isOnGround = true;

          if (particle.countFalls < 4) {
            this.soundFall.play();
            particle.countFalls++;
          }
        } else if (!particle.body.blocked.down) {
          particle.isOnGround = false;
        }
      } catch (e) {
        particle.isOnGround = false;
      }

      if (particle.isOnGround) {
        particle.angularVelocity *= 0.98;
      }
    });
  };

  const getDelay = () => {
    return user.isAction ? Phaser.Math.Between(1000, 2000) : Phaser.Math.Between(2000, 10000);
  };

  return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />;
};

export default PhaserCanvas;