import React, { useState, useEffect, useContext } from "react";
import cls from './header.module.css';
import LanguageModal from "../Language/Language";
import {Context} from '../../index'


const Header = ({ lang}) => {
    const [visible, setVisible] = useState(false);

    const {user} = useContext(Context);

    const [userPhoto, setPhotoUrl] = useState('./images/qw.png')

    useEffect(() => {
        if(user.photo !== '') {
            setPhotoUrl(`${user.photo}`)
        }
        else {
            setPhotoUrl('./images/qw.png')
        }
    }, [user.photo])

    console.log(user.language)
    
  
    return (
        <>
        <header className={cls.header}>
            <button onClick={() => {setVisible(true)}} className={cls.lang}>{user.language ? user.language : "en"}</button>
            <img src={userPhoto} alt=""></img>
        </header>
        <LanguageModal visible={visible} setVisible={setVisible}></LanguageModal>
        </>
        
    );
}

export default Header;
