import React, { useEffect } from "react";
import cls from './Count.module.css'

function Count({count}) {
  

  return (
    <div className={cls.count}>
        <img src="./images/icons/nut.svg" alt=""></img>
        <span>{count}</span>
    </div>
  );
}

export default Count;
