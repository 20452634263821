import React, { useContext, useEffect, useState } from "react";
import cls from './Task.module.css';
import { Link } from "react-router-dom";
import { checkSub, coinsPost, updateUser } from '../../http/userAPI';
import { Context } from "../..";

const tg = window?.Telegram?.WebApp;

async function isValidTelegramLink(url, id, prise, user, taskId) {

    if(url.startsWith('https://t.me/')) {
        return await checkSub(Number(tg?.initDataUnsafe?.user?.id), id, prise);
    }
    else {
        return await coinsPost(user.id, prise, id);
    }
  }

function Task({ icon, text, prise, status, size, index, id, link, setVisible }) {
    const [statusTask, setStatus] = useState(false);

    const {user} = useContext(Context);
    const [isWaiting, setIsWaiting] = useState(false);
    let timer;

    const handleCheckSubscribe = async () => {
        try {
            setIsWaiting(true);
            
            timer = setTimeout(async()=> {
                //const answer = await checkSub(Number(tg?.initDataUnsafe?.user?.id), tg?.initDataUnsafe?.user?.username, id, prise);
                const answer = await isValidTelegramLink(link, id, prise, user);
                console.log(answer);
                if(answer.data) {
                    setStatus(true);
                    user.setCoins(user.coins + prise);
                    setVisible(true);
                    setTimeout(() => {
                        setVisible(false);
                    }, 2000)
                    setIsWaiting(false);
                }
                else {
                    setIsWaiting(false);
                }
               
            }, 4000);
            
            
        } catch (error) {
            console.error('Error checking subscription:', error);
        }
    };

    const getButton = (status) => {
        if (status) {
            return (
                <div className={cls.done}>
                    <img src="./images/icons/done.svg" alt="Done" />
                </div>
            );
        }
        if(isWaiting) {
            return (
                <div className={cls.done}>
                    <img className={cls.loading} src="./images/img/loading.png" alt="Done" />
            </div>
            )
        }
        return (
            <a className={cls.work} href={link} target="_blank" rel="noopener noreferrer" onClick={handleCheckSubscribe}>
                GO
            </a>
        );
    };

    useEffect(() => {
        setStatus(status)
        return clearTimeout(timer);
    }, [])

    return (
        <div className={cls.taskCont} style={{zIndex:size - index}}>
            <div
                className={cls.task}
            >
                <div className={cls.leftContainer}>
                    <img src={icon} alt="Task Icon" />
                    <div className={cls.textCont}>
                        <span className={cls.header}>{text}</span>
                        <span className={cls.price}>
                            +{prise}
                            <img className={cls.nut} alt="Nut Icon" src="./images/icons/nut.svg" />
                        </span>
                    </div>
                </div>
                <div className={cls.rightContainer}>
                    {getButton(statusTask)}
                </div>
            </div>

            
        </div>
    );
}

export default Task;
