import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useHandleBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      // Если текущий путь не главная страница, перенаправляем на главную
      if (window.location.pathname !== '/') {
        navigate('/', { replace: true }); // Переход на главную страницу
        return true; // Прерываем стандартное поведение
      }
      return false; // Позволяем выходить из приложения только если на главной
    };

    // Перехватываем событие нажатия кнопки назад
    window.Telegram?.WebApp?.onEvent('backButtonClicked', handleBackButton);

    // Удаляем обработчик при размонтировании компонента
    return () => {
      window.Telegram?.WebApp?.offEvent('backButtonClicked', handleBackButton);
    };
  }, [navigate]);
};

export default useHandleBackButton;
