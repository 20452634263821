import React from "react";
import cls from './FaqContent.module.css';
import { Link } from "react-router-dom";
import FaqList from "../FaqList/FaqList";

function FaqContent({ lang, userPhoto}) {
   
    return (
        <div className={cls.main} style={{padding:'0 15px'}}>
            <h1 className={cls.header}>Frequent Questions</h1>
            <FaqList></FaqList>
            
        </div>
    );
}

export default FaqContent;
