import React, { useEffect } from "react";
import cls from './style.module.css'
function DekstopCont() {
  

  return (
 <>
 <div className={cls.main}>
    <div className={cls.backphone}></div>
     <img src='./images/img/qr.png' style={{widows:'26vw', height:"26vw"}}></img>
 <span className={cls.message}>Only for mobile</span>
 </div>
  
 </>
  );
}

export default DekstopCont;
