import React, { useContext, useEffect, useState } from "react";
import cls from './TasksList.module.css';
import Task from "../Task/Task";
import { getTasks } from "../../http/userAPI";
import { Context } from "../..";

function TaskList({ setTaskCount }) {
    const [tasks, setTasks] = useState([]); 
    const [modalCls, setModalCls] = useState([cls.succsesModal]);
    const { user } = useContext(Context);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const answer = await getTasks(user.id);
                console.log(answer);
                const taskData = answer.data.map(el => ({
                    icon: el.task.image,
                    text: el.task.description,
                    prise: el.task.reward,
                    status: el.status !== 'pending',
                    id: el.id,
                    link: el.task.link
                }));
                console.log(taskData);
                setTasks(taskData);
                setTaskCount(taskData.length);
            } catch (error) {
                console.error("Failed to fetch tasks:", error);
            }
        };

        fetchTasks();
    }, [user.id, setTaskCount]);

    useEffect(() => {
        if (modalVisible) {
            setModalCls([...modalCls, cls.active]);
        } else {
            setModalCls(modalCls.filter(clsName => clsName !== cls.active));
        }
    }, [modalVisible]); // Include modalVisible in dependency array


    return (
        <div className={cls.taskList}>
            <div className={modalCls.join(' ')}>
                <img src="images/icons/gal.svg" alt="gal" />{'succses'}
            </div>
            {tasks.map((el, index) => (
                <Task 
                    key={index}
                    icon={el.icon}
                    text={el.text}
                    prise={el.prise}
                    status={el.status}
                    size={tasks.length}
                    index={index}
                    id={el.id}
                    link={el.link}
                    setVisible={setModalVisible}
                />
            ))}
        </div>
    );
}

export default TaskList;
